import { useLocation } from "react-router-dom";

const BackStyle = () => {
    const location = useLocation(); // usarlo para obtener la ruta actua y cambiar el background
    console.log(location.pathname);
    return (
        <section id="bgStyle" className="">
            <div className="layer layer1"></div>
            <div className="layer layer2"></div>
            <div className="layer layer3"></div>
            <div className="layer layer4"><div className="inlayer"></div></div>
            <div className="layer layer5"></div>
            <div className="layer layer6"></div>
            <div className="layer layer7"></div>
            <div className="layer layer8"></div>
            <div className="layer layer9"></div>
        </section>
    )
}

export default BackStyle