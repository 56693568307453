import React from 'react'
import logo from '../assets/img/Asset 5@4x.png'
import { BsFillPhoneFill, BsFillEnvelopeFill, BsFillGeoAltFill, BsWhatsapp } from 'react-icons/bs'
const Footer = () => {
    return (
        <footer className=' bg-[#001E29] fixed bottom-0 w-[100%] z-30 '>
            <div className='flex align-middle justify-between py-3 sm:pb-5 px-3 xl:px-6'>
                <div className='lg:grid align-middle justify-start'>
                    <ul className=' flex pt-1 text-sm'>
                        <li className=' flex items-end'><a href="tel:+5491165307070" className='flex' ><BsFillPhoneFill className='w-6 h-6 text-white' /><p className='bg-slate-50 rounded-md px-1  hidden lg:block'>+5491165307070</p></a><a href=" https://wa.me/5491165307070" target='_blank' className=' pl-2 w-6 h-6'><BsWhatsapp className='w-6 h-6 text-white ' /></a></li>
                    </ul>
                    <ul className=' flex pt-1 text-sm'>
                        <li className=' flex items-end'><BsFillEnvelopeFill className='w-6 h-6 text-white' /><a href="mailto:mturic@ambientaldelsud.com.ar" className='flex px-1'><p className='bg-slate-50 rounded-md px-1 hidden lg:block'>mturic@ambientaldelsud.com.ar</p></a></li>
                        <li className=' flex items-end'><BsFillGeoAltFill className='w-6 h-6 text-white' /><p className='bg-slate-50 rounded-md px-1 hidden lg:block'>Tigre, Buenos Aires, Argentina</p></li>
                    </ul>
                </div>
                <div className=' text-gray-500 text-center self-end'>© Ambiental del Sud.</div>
                <img className='logo self-center  w-8 h-8 sm:w-14 lg:w-18 sm:h-14 lg:h-18 2xl:w-26 2xl:h-26' src={logo} alt='logo' />
            </div>
        </footer>
    )
}

export default Footer