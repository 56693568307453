import { BrowserRouter, Routes, Route } from "react-router-dom"
import NavBar from "./components/NavBar"
import Home from "./components/Home"
import Footer from "./components/Footer"
import logo from "./assets/img/Asset 5@4x.png"
import BackStyle from "./components/BackStyle"
const App = () => {
    return (
        <BrowserRouter>
            <div className="App bg-slate-800 grid max-h-full max-w-full">
                <BackStyle />
                <header className="w-full z-30 bg-white flex px-6 py-2 fixed top-0 items-center justify-between rounded-b-2xl shadow-xl">
                    <a href="/" className='headerLogo'>
                        <img className='logo w-12 sm:w-14 2xl:w-20 2xl:w-26' src={logo} alt='logo' />
                    </a>
                    <NavBar />
                </header>
                <main className="w-[90%] min-h-[70vh] z-20 px-4 py-2 top-[10vh] justify-self-center absolute self-center">
                    <Routes>
                        <Route path="/" element={<Home />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </BrowserRouter>
    )
}

export default App