import elLogo from '../assets/img/Asset 4@3x.png'
const Home = () => {
    return (
        <div className='items-start max-content inline-flex
        translate-y-[55%] translate-x-[5%] lg:translate-x-[10%] xl:translate-x-[15%] '>
            <img src={elLogo} alt="Logo Ads" className='w-[4.5rem] md:w-[7rem] lg:w-[9.5rem]' />
            <span className='text-[#0079A9] font-semibold text-5xl md:text-7xl lg:text-8xl pl-3'>Ambiental <br /> del Sud</span>
        </div>
    )
}

export default Home
// Hacer una animacion de entrada a la pagina en la que se destape el logo y aparescan de arriba y abajo el footer y el navbar con las layer moviendose una sobre la otra.
