import React, { useState } from 'react'

import { HiMenu, HiX } from 'react-icons/hi'
import { NavLink } from 'react-router-dom'
import logo from '../assets/img/logo_estrellas.png'
// import BotonIdioma from './BotonIdioma'
const NavBar = () => {
    const [showNav, setShowNav] = useState(false)
    const navItems = [{ title: 'Inicio', link: '/' }, { title: 'Servicios', link: '/' }, { title: 'La Empresa', link: '/' }, { title: 'Contacto', link: '/' }]
// Hcaerle una animacion en la que baje el header , esconda los iconos y bajen los items del navbar y el icono de cerrar.
    return (
        <>
            <HiMenu onClick={() => setShowNav(!showNav)} className='h-10 w-10 lg:hidden cursor-pointer self' />
            <ul className={(showNav ? '' : 'hidden') + ' fixed bg-white z-40 overflow-visible top-0 right-0 z-18 w-full h-screen text-center text-2xl font-semibold  space-y-12 transition-top duration-[2000ms]  lg:w-2/3 lg:h-auto lg:pt-0 lg:mr-3 lg:space-y-0 lg:text-lg lg:flex lg:items-center lg:justify-between lg:right-5 lg:top-auto xl:w-1/2 xl:text-xl 2xl:w-2/5 2xl:text-2xl'}>
                <div className='flex justify-between px-6 lg:hidden mb-12'>
                    <img className='w-12 mt-3' src={logo} alt="logo" />
                    <HiX onClick={() => setShowNav(!showNav)} className='h-10 w-10 cursor-pointer mt-4' />
                </div>
                {navItems.map((item, index) => {
                    return (
                        <li key={index} className='px-6 text-white font-semibold'>
                            <NavLink to={item.link} className={({ isActive }) =>
                                isActive ? 'text-[#0097D3] px-1 rounded-lg hover:text-[#0079A9]' : ' rounded-lg cursor-pointer px-1 text-[#005B7E] hover:text-[#0097D3]'
                            }>{item.title}</NavLink>
                        </li>
                    )
                }
                )}
            </ul>
        </>

    )
}

export default NavBar